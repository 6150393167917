import { FadeAnimation, FieldError, Icon } from "@kolmeo/ui-components";
import { Box, Text, useTheme } from "@kolmeo/ui-core";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import FormStepSelector from "@src/components/FormStepSelector/FormStepSelector";
import { PageContainer } from "@src/containers";
import { PaymentSettingsStackParamsList } from "@src/navigation/PaymentSettingsNavigator";
import { useBranding } from "@src/navigation/useBranding";
import { useCustomNavigation } from "@src/navigation/hooks/useCustomNavigation";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useGlobalTenant } from "@src/navigation/hooks/useGlobalTenant";
import ModalCancelForm from "@src/components/ModalCancelForm/ModalCancelForm";
import { useMediaQuery } from "@src/hooks";
import { useAddBankAccountMutation, useGetAllBankAccounts } from "../../hooks/usePaymentPreferences";
import FormStepOne from "./FormStepOne";
import FormStepTwo from "./FormStepTwo";
import { AlertModal } from "@src/components";
import * as bsbData from "@src/data/BSBDirectoryNov24-344.json";
import { BSBDataType } from "@src/types/misc/bsbDataType";
import { AddPaymentMethodsFormData } from "@src/types/PaymentPreferences";
import { Linking, Platform } from "react-native";
import {
  NUMBER_REGEX,
  MAX_BSB_NUMBER,
  MIN_ACCOUNT_NUMBER,
  MAX_ACCOUNT_NUMBER,
  MAX_NAME,
  MAX_NICKNAME
} from "@src/types/constants/PaymentMethods";
import { DEFAULT_NICKNAME, TAC_URL } from "../constants";

const AddPaymentMethod = ({ navigation }: NativeStackScreenProps<PaymentSettingsStackParamsList, "AddPaymentMethod">) => {
  const { brandingObject } = useBranding();
  const { colors } = useTheme();
  const { goBack } = useCustomNavigation();
  const { tenancyId, handleSetRedirectId } = useGlobalTenant();
  const { t } = useTranslation(["Common", "Tenant"]);
  const { isBelowTablet } = useMediaQuery();
  const { mutateAsync, isLoading, isError } = useAddBankAccountMutation();
  const { data, isPreviousData, refetch } = useGetAllBankAccounts(tenancyId); // used to check if added bank account should be automatically set to default
  const [showCompletedModal, setShowCompletedModal] = useState(false);
  const [isCancelModalActive, setIsCancelModalActive] = useState<boolean>(false);
  const [selectedFormStep, setSelectedFormStep] = useState(0);
  // enables/disables the tab bar
  const [isStepOneIncomplete, setIsStepOneIncomplete] = useState(true);
  const [isStepTwoIncomplete, setIsStepTwoIncomplete] = useState(true);
  const [foundBsbDetails, setFoundBsbDetails] = useState<BSBDataType>();
  const [formLoading, setFormLoading] = useState(false);

  const handleBackLink = () => navigation.canGoBack() && goBack();

  const onTabPress = (item: number) => {
    setSelectedFormStep(item);
  };

  const formSteps = [
    { label: t("Tenant:PaymentMethods.BankDetails"), value: 0, isComplete: !isStepOneIncomplete, isDisabled: false },
    { label: t("Tenant:PaymentMethods.SignAuthority"), value: 1, isComplete: !isStepTwoIncomplete, isDisabled: isStepOneIncomplete }
  ];
  // hold state of form here
  const generalRequestSchema = yup
    .object()
    .shape({
      routingNumber: yup
        .string()
        .required(t("Common:Forms.ErrorBSBRequired"))
        .matches(NUMBER_REGEX, t("Common:Forms.ErrorBSBRequired"))
        .test("CheckBsbValid", t("Common:Forms.ErrorBSBRequired"), (val) => {
          return fetchBSBData(val).then((res) => {
            return res;
          });
        })
        .min(MAX_BSB_NUMBER, t("Common:Forms.ErrorMessageExactDigitsReq", { fieldName: "BSB", exactCharacters: MAX_BSB_NUMBER }))
        .max(MAX_BSB_NUMBER, t("Common:Forms.ErrorMessageExactDigitsReq", { fieldName: "BSB", exactCharacters: MAX_BSB_NUMBER })),
      accountNumber: yup
        .string()
        .required(t("Common:Forms.ErrorAccountNumberRequired"))
        .matches(NUMBER_REGEX, t("Common:Forms.ErrorAccountNumberRequired"))
        .min(
          MIN_ACCOUNT_NUMBER,
          t("Common:Forms.ErrorMinGreaterThanDigitsReq", {
            fieldName: t("Tenant:PaymentMethods.AccountNumber"),
            minCharacters: MIN_ACCOUNT_NUMBER
          })
        )
        .max(
          MAX_ACCOUNT_NUMBER,
          t("Common:Forms.ErrorMaxCharacters", {
            fieldName: t("Tenant:PaymentMethods.AccountNumber"),
            maxCharacters: MAX_ACCOUNT_NUMBER
          })
        ),
      accountName: yup
        .string()
        .required(t("Common:Forms.ErrorAccountNameRequired"))
        .max(
          MAX_NAME,
          t("Common:Forms.ErrorMaxCharacters", { fieldName: t("Tenant:PaymentMethods.AccountName"), maxCharacters: MAX_NAME })
        ),
      nickname: yup
        .string()
        .max(
          MAX_NICKNAME,
          t("Common:Forms.ErrorMaxCharacters", { fieldName: t("Tenant:PaymentMethods.AccountNickname"), maxCharacters: MAX_NICKNAME })
        ),
      userAcceptedTermsAndConditions: yup.boolean().required().oneOf([true], t("Common:Forms.ErrorDebitAuthority")),
      userViewedServiceAgreement: yup.boolean(),
      makeDefaultPaymentAccount: yup.boolean()
    })
    .required();

  const {
    control,
    getFieldState,
    getValues,
    trigger,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty }
  } = useForm<AddPaymentMethodsFormData>({
    defaultValues: {
      tenancyId: tenancyId,
      routingNumber: undefined,
      accountNumber: undefined,
      financialInstitutionName: "",
      accountName: "",
      nickname: "",
      country: "",
      userAcceptedTermsAndConditions: false,
      makeDefaultPaymentAccount: false,
      userViewedServiceAgreement: false
    },
    mode: "onBlur",
    resolver: yupResolver(generalRequestSchema)
  });

  const onSubmit = async (formData: AddPaymentMethodsFormData) => {
    trigger().then(() => {
      setFormLoading(true);
      try {
        if (formData.nickname == "") {
          formData.nickname = DEFAULT_NICKNAME;
        }
        if (data?.length == 0) {
          formData.makeDefaultPaymentAccount = true;
        }
        mutateAsync(formData).then((res) => {
          refetch()
            .then(() => {
              if (!isPreviousData) {
                handleSetRedirectId(res.paymentAccountId);
              }
            })
            .finally(() => {
              setShowCompletedModal(true);
              setFormLoading(false);
            });
        });
      } catch (error) {
        console.log(error, "GeneralRequest:: ERROR");
        setFormLoading(false);
      }
    });
  };

  const onError = async (err: any) => {
    console.log(err, "onError:: formData errors");
  };

  const fetchBSBData = async (keyVal?: string) => {
    if (keyVal) {
      const chars = [...keyVal];
      chars.splice(3, 0, "-");
      const formattedKey = chars.join("");
      const bsbArr = Object.values(bsbData);
      const data: BSBDataType | undefined = bsbArr.find((bsb) => bsb.BSB == formattedKey);
      if (data) {
        setValue("financialInstitutionName", `${data.BankName} ${data.FIELD3}`);
        setFoundBsbDetails(data);
        return true;
      } else {
        setFoundBsbDetails(undefined);
        return false;
      }
    } else {
      return false;
    }
  };
  const onTACPRess = () => {
    setValue("userViewedServiceAgreement", true);
    Linking.openURL(TAC_URL);
    // if (Platform.OS == "web") {
    //   window.open(`/tac`, "_blank");
    // } else {
    //   Linking.openURL("TODO: Add external url");
    // }
  };
  const onDDAgreementPress = () => {
    setValue("userViewedServiceAgreement", true);
    if (Platform.OS == "web") {
      window.open(`/tac`, "_blank");
    } else {
      // TODO: add navigation functionalit for mobile.
      Linking.openURL("TODO: Add external url");
    }
  };

  const { accountName, routingNumber, accountNumber, nickname } = watch();
  useEffect(() => {
    validateStepOne();
  }, [accountName, routingNumber, accountNumber, nickname]);

  const validateStepOne = () => {
    let isStepCompleted = false;
    if (accountName && routingNumber && accountNumber) {
      trigger(["accountName", "nickname", "accountNumber", "routingNumber"]).then(() => {
        if (getFieldState("accountNumber").error) {
          isStepCompleted = true;
        } else if (getFieldState("accountName").error) {
          isStepCompleted = true;
        } else if (getFieldState("routingNumber").error) {
          isStepCompleted = true;
        }
        if (nickname && getFieldState("nickname").error) {
          isStepCompleted = true;
        }

        setIsStepOneIncomplete(isStepCompleted);
        return true;
      });
    }
  };

  const validateStepTwo = (fieldName?: keyof AddPaymentMethodsFormData) => {
    if (fieldName) {
      trigger(fieldName).then(() => {
        if (getFieldState(fieldName as keyof AddPaymentMethodsFormData).invalid) {
          setIsStepTwoIncomplete(true);
        } else {
          setIsStepTwoIncomplete(false);
        }
      });
    } else {
      if (getFieldState("userAcceptedTermsAndConditions").invalid) {
        setIsStepTwoIncomplete(true);
      } else {
        setIsStepTwoIncomplete(false);
        return true;
      }
    }
  };

  const onNextPress = () => {
    // if the following fields are valid, then set active tab to the next one
    trigger(["accountName", "nickname", "accountNumber", "routingNumber"]).then(() => {
      if (isStepOneIncomplete == false) {
        setSelectedFormStep(selectedFormStep + 1);
      }
    });
  };

  // set cancelation modal or conduct back navigation
  const handleCancelFormPress = () => {
    if (isDirty) {
      setIsCancelModalActive(true);
    } else {
      handleBackLink();
    }
  };

  return (
    <PageContainer
      brandingObject={brandingObject}
      headingText={t("Tenant:PaymentMethods.AddNewBankAccountHeader")}
      navigateBack={handleCancelFormPress}
      isScrollable
      iconType="bank"
      navigateBackLabel={t("Common:NavigationLabels.PaymentSettings")}
    >
      <Box marginTop={3}>
        <FormStepSelector<number>
          options={formSteps}
          onTabPress={(item) => onTabPress(item)}
          activeItem={selectedFormStep}
          activeColor={brandingObject?.primaryColor}
        />
        {selectedFormStep == 0 && (
          <Box flex={1} width={isBelowTablet ? "100%" : "40%"}>
            <FormStepOne
              control={control}
              errors={errors}
              handleBackPress={handleCancelFormPress}
              handleNextPress={onNextPress}
              isLoading={isLoading}
              expandToFullWidth={isBelowTablet}
              brandingObject={brandingObject}
              bsb={foundBsbDetails}
              hideSetToDefaultControl={data?.length == 0}
            />
          </Box>
        )}
        {selectedFormStep == 1 && (
          <FadeAnimation enterDuration={100} exitDuration={100}>
            <Box flex={1} width={isBelowTablet ? "100%" : "50%"}>
              <FormStepTwo
                control={control}
                errors={errors}
                handleBackPress={() => setSelectedFormStep(selectedFormStep - 1)}
                handleNextPress={handleSubmit(onSubmit, onError)}
                isLoading={formLoading}
                expandToFullWidth={isBelowTablet}
                brandingObject={brandingObject}
                validationTrigger={validateStepTwo}
                onTACPRess={onTACPRess}
                onDDAgreementPress={onDDAgreementPress}
              />
              <Box paddingTop={3}>{isError && <FieldError message={"A general error occurred."} />}</Box>
            </Box>
          </FadeAnimation>
        )}
      </Box>
      <ModalCancelForm
        toggleVisibility={() => setIsCancelModalActive(!isCancelModalActive)}
        responsivenessFlag={isBelowTablet}
        onConfirmPress={() => {
          setIsCancelModalActive(false);
          handleBackLink();
        }}
        isVisible={isCancelModalActive}
        brandingObject={brandingObject}
        altContent={t("Tenant:PaymentMethods.CancelFormWarningDescription")}
      />
      {/* completed modal */}
      <AlertModal
        heading={t("Tenant:PaymentMethods.BankAccountAddedSuccessHeader")}
        iconType={"success"}
        customIcon={<Icon type={"hand-click"} size={160} color={colors.primary} width={297} height={160} />}
        content={
          <>
            <Text variant="paragraph14">{t("Tenant:PaymentMethods.BankAccountAddedSuccessDescription")}</Text>
            {data?.length == 0 ||
              (getValues("makeDefaultPaymentAccount") == true && (
                <Text marginTop={3} variant="paragraph14">
                  {t("Tenant:PaymentMethods.BankAccountAddedSuccessDefaultSet")}
                </Text>
              ))}
          </>
        }
        brandingObject={brandingObject}
        isVisible={showCompletedModal}
        toggleVisibility={() => {
          setShowCompletedModal(false);
          // form is reset after the user goes back,
          navigation.goBack();

          // navigation.dispatch(StackActions.replace("Details", { tenancyId }));
        }}
      />
    </PageContainer>
  );
};

export default AddPaymentMethod;
